import Swiper from "swiper";
import { Pagination, Navigation, Mousewheel, Autoplay } from "swiper/modules";
import { mq } from "../modules/utils";

import "../sass/app-top.scss";

const g = {
  html: document.getElementsByTagName("html")[0],
  body: document.getElementsByTagName("body")[0],
};

/**
 * メインビジュアルのスライダー SP時
 */
const spSliderFn = () => {
  const slider = document.querySelector(".js-slider");
  if (!slider) return;

  let scrollValue;
  let ticking = false;

  const option = {
    modules: [Pagination, Mousewheel],
    direction: "vertical",
    speed: 600,
    cssMode: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    mousewheel: {
      releaseOnEdges: true,
    },
  };

  const swiper = new Swiper(slider, option);

  const onScroll = () => {
    scrollValue = window.scrollY;
    if (!ticking) {
      requestAnimationFrame(() => {
        if (scrollValue > 1) {
          // スワイパー領域外
          swiper.disable();
          slider.style.pointerEvents = "none";
        } else {
          // スワイパー領域に入った場合
          slider.style.pointerEvents = "initial";
          swiper.enable();
        }
        ticking = false;
      });
      ticking = true;
    }
  };

  window.addEventListener("scroll", onScroll);
};

/**
 * メインビジュアルのスライダー PC時
 */
const pcSliderFn = () => {
  const slider = document.querySelector(".js-slider");
  if (!slider) return;

  const option = {
    modules: [Navigation, Autoplay],
    speed: 600,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    autoplay: {
      disableOnInteraction: false,
      delay: 4000,
    },
  };

  const swiper = new Swiper(slider, option);

  slider.addEventListener("mouseenter", () => {
    swiper.autoplay.stop();
    swiper.autoplay.pause();
  });
  slider.addEventListener("mouseleave", () => {
    swiper.autoplay.resume();
    swiper.autoplay.start();
  });
};

const subSliderFn = () => {
  const sliders = document.querySelectorAll(".js-slider-sub");
  if (sliders.length === 0) return;

  const options = {
    modules: [Autoplay, Navigation],
    speed: 600,
    loop: true,
    autoplay: {
      disableOnInteraction: false,
      delay: 4000,
    },
    slidesPerView: 1.5,
    spaceBetween: "40px",
    centeredSlides: true,
    // centerInsufficientSlides: true,
  };

  sliders.forEach((slider) => {
    const swiper = new Swiper(slider, options);
    slider.addEventListener("mouseenter", () => {
      swiper.autoplay.stop();
      swiper.autoplay.pause();
    });
    slider.addEventListener("mouseleave", () => {
      swiper.autoplay.resume();
      swiper.autoplay.start();
    });
  });
};

document.addEventListener("DOMContentLoaded", () => {
  if (mq("sp")) spSliderFn();
  if (mq("pc")) pcSliderFn();
  subSliderFn();
});

window.addEventListener("load", () => {});
